import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

interface ISite {
  site: {
    siteMetadata: {
      description: string,
      title: string,
    },
  }
}

const SEO = ({ description, lang, title }: {description?: string, lang: string, title: string}) => {
  const { site: { siteMetadata} }: ISite = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );
  const metaDescription = description || siteMetadata.description
  const defaultTitle = siteMetadata.title || "Fuzzbuzz Docs"

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : ""}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `viewport`,
          content: `width=device-width, initial-scale=1, maximum-scale=1`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ]}
    />
  )
}

export default SEO
